import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { Link } from 'react-scroll';
import aboutHorizontalScrollImage from '../img/2078BurningChicagoOrange03.png';
import aboutHorizontalScrollImage01 from '../img/blazingPirateShip02.jpg';
import aboutVerticallScrollImage02 from '../img/blazingTower01.png';
import aboutVerticalScrollImage03 from '../img/blazingsword.jpg';




const About = () => {

  gsap.registerPlugin(ScrollTrigger);
    
  const { ref, inView } = useInView({ threshold: 0.1 });
  const parallaxRef = useRef();
  const sectionRef = useRef(null);

  // État pour gérer si l'appareil est mobile ou non, basé sur la largeur de la fenêtre
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1080);

  // Choix de l'image de fond en fonction de la date et de la taille de l'écran
  const chooseBackgroundImage = (isEvenDay, isMobileView) => {
    if (isMobileView) {
      return isEvenDay ? aboutVerticallScrollImage02 : aboutVerticalScrollImage03;
    } else {
      return isEvenDay ? aboutHorizontalScrollImage : aboutHorizontalScrollImage01;
    }
  };

  // Détermine si le jour est pair ou impair
  const isEvenDay = new Date().getDate() % 2 === 0;

  // Détermine l'image de fond initiale
  const initialBackgroundImage = chooseBackgroundImage(isEvenDay, isMobile);

  // État pour gérer l'image de fond actuelle
  const [backgroundImage, setBackgroundImage] = useState(initialBackgroundImage);

  // Écoutez les changements de taille de la fenêtre et mettez à jour le state `isMobile`
  useEffect(() => {
    const handleResize = () => {
      const currentIsMobile = window.innerWidth < 1080;
      setIsMobile(currentIsMobile);
      setBackgroundImage(chooseBackgroundImage(isEvenDay, currentIsMobile));
    };
  
    // Ici, nous devons nous assurer que nous passons la fonction handleResize à addEventListener
    window.addEventListener('resize', handleResize);
  
    // Le nettoyage doit toujours retourner une fonction
    return () => window.removeEventListener('resize', handleResize);
  }, [isEvenDay]);

// GSAP Animation pour l'effet parallaxe
useEffect(() => {
    const elements = sectionRef.current ? sectionRef.current.querySelectorAll('h2, p') : null;
    if (elements && elements.length > 0) {
      gsap.fromTo(elements, 
        { backgroundSize: '0% 100%' },
        {
          backgroundSize: '100% 100%',
          ease: 'none',
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top center',
            end: 'center center',
            scrub: true,
            onEnter: () => console.log('Animation triggered'),
          },
        }
      );
    }

    if (parallaxRef.current) {
        // Définir la position initiale
        gsap.set(parallaxRef.current, {
          backgroundPositionX: '0px', // ou '0%' si vous travaillez avec des pourcentages
        });
    
        // Animation pour mobile
        if (isMobile) {
          gsap.to(parallaxRef.current, {
            backgroundPositionY: (i, target) => {
              // Retourne une position de fond calculée en fonction de la hauteur du conteneur
              return `-${target.getBoundingClientRect().height / 2}px`;
            },
            ease: 'none',
            scrollTrigger: {
              trigger: parallaxRef.current,
              start: 'top bottom',
              end: 'bottom top',
              scrub: true
            }
          });
        } else {
          // Animation horizontale pour bureau
          gsap.to(parallaxRef.current, {
            backgroundPositionX: (i, target) => {
              // Retourne une position de fond calculée en fonction de la largeur du conteneur
              return `-${target.getBoundingClientRect().width / 2}px`;
            },
            ease: 'none',
            scrollTrigger: {
              trigger: parallaxRef.current,
              start: 'top bottom',
              end: 'bottom top',
              scrub: true
            }
          });
        }
      }
    
      // Capture the current values of the refs pour le cleanup
      const currentParallax = parallaxRef.current;
      const currentSection = sectionRef.current;
    
      return () => {
        ScrollTrigger.getAll().forEach(trigger => {
          if (trigger.trigger === currentParallax || trigger.trigger === currentSection) {
            trigger.kill();
          }
        });
      };
    }, [isMobile]); 


    return (
        <section className="section h-auto bg-site1 bg-cover mt:[-335] xxs:mt[-265]" id='about' ref={ref} style={{block: "center", behavior: "smooth", inline: "center"}}>
            <div className='flex flex-row justify-around items-center reveal2Container'
                ref={parallaxRef}
                style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignContent: 'center', overflow: 'hidden', backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'right center', backgroundRepeat: 'no-repeat', width: '40vw', marginLeft: '10vw', height: '50vh', opacity: '0.95', border: '3px solid #F39C2B' }}
            >
                <div 
                  className='flex flex-col justify-center items-center scroll-reveal2'
                  ref={sectionRef}
                  style={{ 
                            display: 'flex',
                            alignContent: 'center',
                            position: 'relative',
                            textAlign: 'center',
                            justifyContent: 'center',
                            width: 'contain',
                            height: 'auto',
                            backgroundImage: `linear-gradient(90deg, #FFF, #B4571C)`,
                            backgroundClip: 'text',
                            color: 'transparent',
                            backgroundSize: '0% 100%',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            padding:'1rem',
                         }}
                
                >
                    <h2><span id="section-titleVertical">Découvrez Mon Expertise</span></h2>
                    <p>
                        <span>
                        En tant que développeur web déterminé et passionné, je façonne l'expérience numérique avec finesse et innovation...
                        </span>
                    </p>
                </div>
            </div>
            <div className='container mx-auto' style={{ display: 'flex', width: '100%', minWidth: '50vw', marginLeft: '-35vw', marginTop: '-20vh' }}>
                <div className='flex lg:flex-row flex-col justify-between items-center lg:items-start gap-10 lg:gap-20 h-full'>
                    {/* Contenu de gauche (image ou autre) */}
                    <motion.div
                        variants={fadeIn("left", 0.5)}
                        initial="hidden"
                        animate="show"
                        className='flex-1 flex flex-col gap-y-6 items-start'
                    >
                        {/* Insérez ici le contenu de gauche si nécessaire */}
                    </motion.div>

                    {/* Contenu de droite (textes et compteurs) */}
                    <motion.div
                        variants={fadeIn("right", 0.5)}
                        initial="hidden"
                        animate="show"
                        className='flex-1 flex flex-col gap-y-6 items-start decritpionAboutMe jstify-center items-center'
                    >
                        {/* Titre, sous-titre et paragraphe */}
                        <h2 className='text-5xl 2xl:text-4xl xl:text-4xl lg:text-3xl md:text-3xl sm:text-3xl xs:text-2xl xxs:text-2xl xxxs:text-2xl xxxxs:text-xl xxxxs:ml-10  font-bold text-brown-700 uppercase mb-8 xxs:mb-16 xxxs:mb-12' style={{ color: '#F5981D', fontWeight: '700', textTransform: 'uppercase', height: '55vh', paddingTop: '32rem' }}>À propos de moi</h2>
                        <h3 className='text-xl xxs:text-lg xxxs:text-base xxxs:text-base xxxxs:text-base font-bold text-orange-500 uppercase mb-4 lg:mb-8 xxs:mt-6 leading-tight sm:leading-snug md:leading-normal lg:leading-relaxed xxxxs:ml-10'>Je suis un développeur web fullstack freelance avec 4 années d'expérience.</h3>
                        <p className='mb-6' style={{ backgroundColor: 'rgba(0,0,0, 0.5)', padding: '2rem' }}>
                            En tant que développeur web fullstack freelance, j'ai acquis une solide expérience au cours de ces deux dernières années. Mon parcours m'a permis de maîtriser les aspects techniques et esthétiques du développement web. Je suis passionné par la création d'expériences utilisateur exceptionnelles en combinant mon expertise en HTML, CSS, JavaScript et d'autres technologies comme React, Angular, Tailwind et bien d'autres. Mon objectif est de concevoir des sites web innovants et fonctionnels qui répondent aux besoins de mes clients tout en privilégiant une esthétique soignée. Mon portfolio est le reflet de mon engagement envers l'excellence dans le monde du développement web.
                        </p>

                        {/* Conteneur pour les compteurs */}
                        <div className='flex flex-row justify-around items-center w-full compteurAbout'>
                            {/* Compteur Années d'expérience */}
                            <div className='text-center'>
                                <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl xxxxs:text-xl font-semibold text-orange-500'>
                                    {inView ? <CountUp end={4} duration={3.5} /> : '0'}
                                </h3>
                                <p className='pAboutCount pAboutCountA text-2xl xs:text-xl xxs:text-lg xxxs:text-sm xxxxs:text-sm text-brown-700'>Années d'expérience</p>
                            </div>

                            {/* Compteur Projets complétés */}
                            <div className='text-center'>
                                <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl xxxxs:text-xl font-semibold text-orange-500'>
                                    {inView ? <CountUp end={220} duration={3.5} /> : '0'}
                                </h3>
                                <p className='pAboutCount text-2xl xs:text-xl xxs:text-lg xxxs:text-sm xxxxs:text-sm text-brown-700'>Projets complétés</p>
                            </div>

                            {/* Compteur Clients satisfaits */}
                            <div className='text-center'>
                                <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl xxxxs:text-xl font-semibold text-orange-500'>
                                    {inView ? <CountUp end={15} duration={3.5} /> : '0'}
                                </h3>
                                <p className='pAboutCount pAboutCountC text-2xl xs:text-xl xxs:text-lg xxxs:text-sm xxxxs:text-sm text-brown-700'>Clients satisfaits</p>
                            </div>
                        </div>
                        <motion.div
                            variants={fadeIn("up", 0.8)}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: false, amount: 0.7 }}
                            className='flex gap-x-6 xxs:gap-x-4 items-center'
                        >
                            <Link to="contact" spy={true} smooth={true} offset={-70} duration={500}
                                className="btnBase4 btn-lg lg:btn-lg md:btn-md sm:btn-sm xs:btn-xs xxs:btn-xxs xxxs:btn-xxxs xxxxs:btn-xxxs flex items-center justify-center"
                            >
                                Contactez-moi
                            </Link>
                            <a href="https://www.youtube.com/channel/UCQ6Zr5qXWZqkXZ5r9ZbQY0w" target="_blank" rel="noreferrer"
                                className="font-bold text-2xl text-[#F5981D] hover:text-[#875410] transition duration-300 ease-in-out"
                            >
                                Mon Portfolio
                            </a>
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default About;




// import React, { useRef, useEffect } from 'react';
// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { useInView } from 'react-intersection-observer';
// import CountUp from 'react-countup';
// import { motion } from 'framer-motion';
// import { fadeIn } from '../variants';
// import { Link } from 'react-scroll';
// import aboutHorizontalScrollImage from '../img/2078BurningChicagoOrange03.png';

// gsap.registerPlugin(ScrollTrigger);

// const About = () => {

//     const { ref, inView } = useInView({ threshold: 0.1 });

//     const parallaxRef = useRef();
//     const sectionRef = useRef(null);


//     useEffect(() => {
//         // Enregistrez GSAP ScrollTrigger
//         gsap.registerPlugin(ScrollTrigger);
    
//         // Animation de l'image
//         if (parallaxRef.current) {
//             gsap.to(parallaxRef.current, {
//                 backgroundPosition: '100% 0%',
//                 ease: 'none',
//                 scrollTrigger: {
//                     trigger: parallaxRef.current,
//                     start: 'top bottom',
//                     end: 'bottom top',
//                     scrub: true
//                 }
//             });
//         }


        
//         // Animation du texte
//         if (sectionRef.current) {
//             gsap.fromTo(sectionRef.current.querySelectorAll('h2, p'), 
//                 { backgroundSize: '0% 100%' },
//                 {
//                     backgroundSize: '100% 100%',
//                     ease: 'none',
//                     scrollTrigger: {
//                         trigger: sectionRef.current,
//                         start: 'top center',
//                         end: 'center center',
//                         scrub: true,
//                         onEnter: () => console.log('Animation triggered'),
//                     },
//                 }
//             );
//         }
    
//         // Nettoyage
//         return () => {
//                     // Nettoyez uniquement les triggers spécifiques associés à parallaxRef et sectionRef
//         if (parallaxRef.current) {
//             ScrollTrigger.getAll().forEach(trigger => {
//                 if (trigger.trigger === parallaxRef.current) {
//                     trigger.kill();
//                 }
//             });
//         }


//         if (sectionRef.current) {
//             ScrollTrigger.getAll().forEach(trigger => {
//                 if (trigger.trigger === sectionRef.current) {
//                     trigger.kill();
//                 }
//             });
//         }
//         };
//     }, [parallaxRef, sectionRef]);


//     return (
//         <section className="section h-auto bg-site1 bg-cover mt:[-335] xxs:mt[-265]" id='about' ref={ref} style={{block: "center", behavior: "smooth", inline: "center"}}>
//             <div className='flex flex-row justify-around items-center reveal2Container'
//                 ref={parallaxRef}
//                 style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignContent: 'center', backgroundImage: `url(${aboutHorizontalScrollImage})`, backgroundSize: 'cover', backgroundPosition: 'right center', backgroundRepeat: 'no-repeat', width: '40vw', marginLeft: '10vw', height: '50vh', opacity: '0.95', border: '3px solid #F39C2B' }}
//             >
//                 <div 
//                   className='flex flex-col justify-center items-center scroll-reveal2'
//                   ref={sectionRef}
//                   style={{ 
//                             display: 'flex',
//                             alignContent: 'center',
//                             position: 'relative',
//                             textAlign: 'center',
//                             justifyContent: 'center',
//                             width: 'contain',
//                             height: 'auto',
//                             backgroundImage: `linear-gradient(90deg, #FFF, #B4571C)`,
//                             backgroundClip: 'text',
//                             color: 'transparent',
//                             backgroundSize: '0% 100%',
//                             WebkitBackgroundClip: 'text',
//                             WebkitTextFillColor: 'transparent',
//                             padding:'1rem',
//                          }}
                
//                 >
//                     <h2 className="section-title"><span>Découvrez Mon Expertise</span></h2>
//                     <p>
//                         <span>
//                         En tant que développeur web déterminé et passionné, je façonne l'expérience numérique avec finesse et innovation...
//                         </span>
//                     </p>
//                 </div>
//             </div>
//             <div className='container mx-auto' style={{ display: 'flex', width: '100%', minWidth: '50vw', marginLeft: '-35vw', marginTop: '-20vh' }}>
//                 <div className='flex lg:flex-row flex-col justify-between items-center lg:items-start gap-10 lg:gap-20 h-full'>
//                     {/* Contenu de gauche (image ou autre) */}
//                     <motion.div
//                         variants={fadeIn("left", 0.5)}
//                         initial="hidden"
//                         animate="show"
//                         className='flex-1 flex flex-col gap-y-6 items-start'
//                     >
//                         {/* Insérez ici le contenu de gauche si nécessaire */}
//                     </motion.div>

//                     {/* Contenu de droite (textes et compteurs) */}
//                     <motion.div
//                         variants={fadeIn("right", 0.5)}
//                         initial="hidden"
//                         animate="show"
//                         className='flex-1 flex flex-col gap-y-6 items-start decritpionAboutMe'
//                     >
//                         {/* Titre, sous-titre et paragraphe */}
//                         <h2 className='text-5xl 2xl:text-4xl xl:text-4xl lg:text-3xl md:text-3xl sm:text-3xl xs:text-2xl xxs:text-2xl xxxs:text-2xl xxxxs:text-xl xxxxs:ml-10  font-bold text-brown-700 uppercase mb-8 xxs:mb-16 xxxs:mb-12' style={{ color: '#F5981D', fontWeight: '700', textTransform: 'uppercase', height: '55vh', paddingTop: '32rem' }}>À propos de moi</h2>
//                         <h3 className='text-xl xxs:text-lg xxxs:text-base xxxs:text-base xxxxs:text-base font-bold text-orange-500 uppercase mb-4 lg:mb-8 xxs:mt-6 leading-tight sm:leading-snug md:leading-normal lg:leading-relaxed xxxxs:ml-10'>Je suis un développeur web fullstack freelance avec 4 années d'expérience.</h3>
//                         <p className='mb-6' style={{ backgroundColor: 'rgba(0,0,0, 0.5)', padding: '2rem' }}>
//                             En tant que développeur web fullstack freelance, j'ai acquis une solide expérience au cours de ces deux dernières années. Mon parcours m'a permis de maîtriser les aspects techniques et esthétiques du développement web. Je suis passionné par la création d'expériences utilisateur exceptionnelles en combinant mon expertise en HTML, CSS, JavaScript et d'autres technologies comme React, Angular, Tailwind et bien d'autres. Mon objectif est de concevoir des sites web innovants et fonctionnels qui répondent aux besoins de mes clients tout en privilégiant une esthétique soignée. Mon portfolio est le reflet de mon engagement envers l'excellence dans le monde du développement web.
//                         </p>

//                         {/* Conteneur pour les compteurs */}
//                         <div className='flex flex-row justify-around items-center w-full compteurAbout'>
//                             {/* Compteur Années d'expérience */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl xxxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={4} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='pAboutCount text-2xl xs:text-xl xxs:text-lg xxxs:text-sm xxxxs:text-sm text-brown-700'>Années d'expérience</p>
//                             </div>

//                             {/* Compteur Projets complétés */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl xxxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={120} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='pAboutCount text-2xl xs:text-xl xxs:text-lg xxxs:text-sm xxxxs:text-sm text-brown-700'>Projets complétés</p>
//                             </div>

//                             {/* Compteur Clients satisfaits */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl xxxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={15} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='pAboutCount text-2xl xs:text-xl xxs:text-lg xxxs:text-sm xxxxs:text-sm text-brown-700'>Clients satisfaits</p>
//                             </div>
//                         </div>
//                         <motion.div
//                             variants={fadeIn("up", 0.8)}
//                             initial="hidden"
//                             whileInView="show"
//                             viewport={{ once: false, amount: 0.7 }}
//                             className='flex gap-x-6 xxs:gap-x-4 items-center'
//                         >
//                             <Link to="contact" spy={true} smooth={true} offset={-70} duration={500}
//                                 className="btnBase4 btn-lg lg:btn-lg md:btn-md sm:btn-sm xs:btn-xs xxs:btn-xxs xxxs:btn-xxxs xxxxs:btn-xxxs flex items-center justify-center"
//                             >
//                                 Contactez-moi
//                             </Link>
//                             <a href="https://www.youtube.com/channel/UCQ6Zr5qXWZqkXZ5r9ZbQY0w" target="_blank" rel="noreferrer"
//                                 className="font-bold text-2xl text-[#F5981D] hover:text-[#875410] transition duration-300 ease-in-out"
//                             >
//                                 Mon Portfolio
//                             </a>
//                         </motion.div>
//                     </motion.div>
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default About;



// import React, { useRef, useEffect } from 'react';
// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { useInView } from 'react-intersection-observer';
// import CountUp from 'react-countup';
// import { motion } from 'framer-motion';
// import { fadeIn } from '../variants';
// import { Link } from 'react-scroll';
// import aboutHorizontalScrollImage from '../img/2078BurningChicagoOrange03.png';

// gsap.registerPlugin(ScrollTrigger);

// const About = () => {

//     const { ref, inView } = useInView({ threshold: 0.1 });

//     const parallaxRef = useRef();
//     const sectionRef = useRef(null);

//         // Définir une fonction pour attribuer plusieurs refs
//         const setMultipleRefs = (element) => {
//             parallaxRef.current = element;
//             if (typeof inViewRef === 'function') {
//                 sectionRef(element);
//             } else {
//                 sectionRef.current = element;
//             }
//         };

//     useEffect(() => {
//         if (parallaxRef.current) {
//             gsap.to(parallaxRef.current, {
//                 backgroundPosition: '100% 0%',
//                 ease: 'none',
//                 scrollTrigger: {
//                     trigger: parallaxRef.current,
//                     start: 'top bottom', // lorsque le haut de l'élément atteint le bas du viewport
//                     end: 'bottom top', // lorsque le bas de l'élément atteint le haut du viewport
//                     scrub: true // l'animation se déroule sur la durée du scroll dans la section
//                 }
//             });
//         }
//     }, []);


//     useEffect(() => {
//         gsap.registerPlugin(ScrollTrigger);
//         ScrollTrigger.getAll().forEach(t => t.kill()); // Nettoie les instances précédentes
      
//         gsap.fromTo(sectionRef.current.querySelectorAll('h2, p'), 
//           { backgroundSize: '0% 100%' },
//           {
//             backgroundSize: '100% 100%',
//             ease: 'none',
//             scrollTrigger: {
//               trigger: sectionRef.current,
//               start: 'top center',
//               end: 'center center',
//               scrub: true,
//               onEnter: () => console.log('Animation triggered'), // Vérifiez si cela s'affiche dans la console
//             },
//           }
//         );
//       }, []);


//     return (
//         <section className="section h-auto bg-site1 bg-cover mt:[-335] xxs:mt[-265]" id='about' ref={ref} style={{block: "center", behavior: "smooth", inline: "center"}}>
//             <div className='flex flex-row justify-around items-center'
//                 ref={parallaxRef}
//                 style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignContent: 'center', backgroundImage: `url(${aboutHorizontalScrollImage})`, backgroundSize: 'cover', backgroundPosition: 'right center', backgroundRepeat: 'no-repeat', width: '40vw', marginLeft: '10vw', height: '50vh', opacity: '0.95', border: '3px solid #F39C2B' }}
//             >
//                 <div 
//                   className='flex flex-col justify-center items-center scroll-reveal2'
//                   ref={sectionRef}
//                   style={{ 
//                             display: 'flex',
//                             alignContent: 'center',
//                             position: 'relative',
//                             textAlign: 'center',
//                             justifyContent: 'center',
//                             width: 'contain',
//                             height: 'auto',
//                             backgroundImage: `linear-gradient(90deg, #FFF, #B4571C)`,
//                             backgroundClip: 'text',
//                             color: 'transparent',
//                             backgroundSize: '0% 100%',
//                             WebkitBackgroundClip: 'text',
//                             WebkitTextFillColor: 'transparent',
//                             padding:'1rem',
//                          }}
                
//                 >
//                     <h2 className="section-title"><span>Découvrez Mon Expertise</span></h2>
//                     <p>
//                         <span>
//                         En tant que développeur web déterminé et passionné, je façonne l'expérience numérique avec finesse et innovation...
//                         </span>
//                     </p>
//                 </div>
//             </div>
//             <div className='container mx-auto' style={{ display: 'flex', width: '100%', minWidth: '50vw', marginLeft: '-35vw', marginTop: '-20vh' }}>
//                 <div className='flex lg:flex-row flex-col justify-between items-center lg:items-start gap-10 lg:gap-20 h-full'>
//                     {/* Contenu de gauche (image ou autre) */}
//                     <motion.div
//                         variants={fadeIn("left", 0.5)}
//                         initial="hidden"
//                         animate="show"
//                         className='flex-1 flex flex-col gap-y-6 items-start'
//                     >
//                         {/* Insérez ici le contenu de gauche si nécessaire */}
//                     </motion.div>

//                     {/* Contenu de droite (textes et compteurs) */}
//                     <motion.div
//                         variants={fadeIn("right", 0.5)}
//                         initial="hidden"
//                         animate="show"
//                         className='flex-1 flex flex-col gap-y-6 items-start'
//                     >
//                         {/* Titre, sous-titre et paragraphe */}
//                         <h2 className='text-5xl 2xl:text-4xl xl:text-4xl lg:text-3xl md:text-3xl sm:text-3xl xs:text-2xl xxs:text-2xl xxxs:text-2xl xxxxs:text-xl xxxxs:ml-10  font-bold text-brown-700 uppercase mb-8 xxs:mb-16 xxxs:mb-12' style={{ color: '#F5981D', fontWeight: '700', textTransform: 'uppercase', height: '55vh', paddingTop: '32rem' }}>À propos de moi</h2>
//                         <h3 className='text-xl xxs:text-lg xxxs:text-base xxxs:text-base xxxxs:text-base font-bold text-orange-500 uppercase mb-4 lg:mb-8 xxs:mt-6 leading-tight sm:leading-snug md:leading-normal lg:leading-relaxed xxxxs:ml-10'>Je suis un développeur web fullstack freelance avec 4 années d'expérience.</h3>
//                         <p className='mb-6' style={{ backgroundColor: 'rgba(0,0,0, 0.5)', padding: '2rem' }}>
//                             En tant que développeur web fullstack freelance, j'ai acquis une solide expérience au cours de ces deux dernières années. Mon parcours m'a permis de maîtriser les aspects techniques et esthétiques du développement web. Je suis passionné par la création d'expériences utilisateur exceptionnelles en combinant mon expertise en HTML, CSS, JavaScript et d'autres technologies comme React, Angular, Tailwind et bien d'autres. Mon objectif est de concevoir des sites web innovants et fonctionnels qui répondent aux besoins de mes clients tout en privilégiant une esthétique soignée. Mon portfolio est le reflet de mon engagement envers l'excellence dans le monde du développement web.
//                         </p>

//                         {/* Conteneur pour les compteurs */}
//                         <div className='flex flex-row justify-around items-center w-full'>
//                             {/* Compteur Années d'expérience */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={4} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Années d'expérience</p>
//                             </div>

//                             {/* Compteur Projets complétés */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={120} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Projets complétés</p>
//                             </div>

//                             {/* Compteur Clients satisfaits */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={15} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Clients satisfaits</p>
//                             </div>
//                         </div>
//                         <motion.div
//                             variants={fadeIn("up", 0.8)}
//                             initial="hidden"
//                             whileInView="show"
//                             viewport={{ once: false, amount: 0.7 }}
//                             className='flex gap-x-6 xxs:gap-x-4 items-center'
//                         >
//                             <Link to="contact" spy={true} smooth={true} offset={-70} duration={500}
//                                 className="btnBase4 btn-lg lg:btn-lg md:btn-md sm:btn-sm xs:btn-xs xxs:btn-xxs xxxs:btn-xxxs xxxxs:btn-xxxs flex items-center justify-center"
//                             >
//                                 Contactez-moi
//                             </Link>
//                             <a href="https://www.youtube.com/channel/UCQ6Zr5qXWZqkXZ5r9ZbQY0w" target="_blank" rel="noreferrer"
//                                 className="font-bold text-2xl text-[#F5981D] hover:text-[#875410] transition duration-300 ease-in-out"
//                             >
//                                 Mon Portfolio
//                             </a>
//                         </motion.div>
//                     </motion.div>
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default About;




// import React, { useRef, useEffect } from 'react';
// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { useInView } from 'react-intersection-observer';
// import CountUp from 'react-countup';
// import { motion } from 'framer-motion';
// import { fadeIn } from '../variants';
// import { Link } from 'react-scroll';
// import aboutHorizontalScrollImage from '../img/2078BurningChicagoOrange03.png';

// gsap.registerPlugin(ScrollTrigger);

// const About = () => {

//     const { ref: inViewRef, inView } = useInView({ threshold: 0.1 });

//     const parallaxRef = useRef();
//     // const viewportBackgroundFixe = useRef();

    // // Définir une fonction pour attribuer plusieurs refs
    // const setMultipleRefs = (element) => {
    //     parallaxRef.current = element;
    //     if (typeof inViewRef === 'function') {
    //         inViewRef(element);
    //     } else {
    //         inViewRef.current = element;
    //     }
    // };

//     useEffect(() => {
//         if (parallaxRef.current) {
//             // La largeur totale de votre contenu horizontal
//             const horizontalWidth = window.innerWidth * 2; // Exemple: deux fois la largeur de la fenêtre

//             // GSAP animation pour le défilement horizontal
//             gsap.to(parallaxRef.current, {
//                 x: () => `-${horizontalWidth - window.innerWidth}px`, // Déplacement horizontal
//                 ease: 'none',
//                 scrollTrigger: {
//                     trigger: parallaxRef.current,
//                     start: 'top top',
//                     end: () => `+=${horizontalWidth}`,
//                     scrub: true,
//                     pin: true,
//                     anticipatePin: 1,
//                 },
//             });
//         }
//     }, []);

//     // useEffect(() => {
//     //     if (viewportBackgroundFixe.current) {
//     //         gsap.to(viewportBackgroundFixe.current, {
//     //             position: 'fixed',
//     //         });
//     //     }
//     // }
//     // , []);


//     return (
//         <section className="section h-auto bg-site1 bg-cover mt:[-335] xxs:mt[-265]" id='about' ref={setMultipleRefs} >
//             <div className='flex flex-row justify-around items-center'
//                 ref={parallaxRef}
//                 style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignContent: 'center', backgroundImage: `url(${aboutHorizontalScrollImage})`, backgroundSize: 'cover', backgroundPosition: 'right center', backgroundRepeat: 'no-repeat', width: '40vw', marginLeft: '10vw', height: '50vh', opacity: '0.5', border: '3px solid #F39C2B' }}
//             >
//                 <h2 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-white-500' style={{}}>Test</h2>
//             </div>
//             <div className='container mx-auto' style={{ display: 'flex', width: '100%', minWidth: '50vw', marginLeft: '-35vw', marginTop: '-20vh' }}>
//                 <div className='flex lg:flex-row flex-col justify-between items-center lg:items-start gap-10 lg:gap-20 h-full'>
//                     {/* Contenu de gauche (image ou autre) */}
//                     <motion.div
//                         variants={fadeIn("left", 0.5)}
//                         initial="hidden"
//                         animate="show"
//                         className='flex-1 flex flex-col gap-y-6 items-start'
//                     >
//                         {/* Insérez ici le contenu de gauche si nécessaire */}
//                     </motion.div>

//                     {/* Contenu de droite (textes et compteurs) */}
//                     <motion.div
//                         variants={fadeIn("right", 0.5)}
//                         initial="hidden"
//                         animate="show"
//                         className='flex-1 flex flex-col gap-y-6 items-start'
//                     >
//                         {/* Titre, sous-titre et paragraphe */}
//                         <h2 className='text-5xl 2xl:text-4xl xl:text-4xl lg:text-3xl md:text-3xl sm:text-3xl xs:text-2xl xxs:text-2xl xxxs:text-2xl xxxxs:text-xl xxxxs:ml-10  font-bold text-brown-700 uppercase mb-8 xxs:mb-16 xxxs:mb-12' style={{ color: '#F5981D', fontWeight: '700', textTransform: 'uppercase', height: '55vh', paddingTop: '32rem' }}>À propos de moi</h2>
//                         <h3 className='text-xl xxs:text-lg xxxs:text-base xxxs:text-base xxxxs:text-base font-bold text-orange-500 uppercase mb-4 lg:mb-8 xxs:mt-6 leading-tight sm:leading-snug md:leading-normal lg:leading-relaxed xxxxs:ml-10'>Je suis un développeur web fullstack freelance avec 4 années d'expérience.</h3>
//                         <p className='mb-6' style={{ backgroundColor: 'rgba(0,0,0, 0.5)', padding: '2rem' }}>
//                             En tant que développeur web fullstack freelance, j'ai acquis une solide expérience au cours de ces deux dernières années. Mon parcours m'a permis de maîtriser les aspects techniques et esthétiques du développement web. Je suis passionné par la création d'expériences utilisateur exceptionnelles en combinant mon expertise en HTML, CSS, JavaScript et d'autres technologies comme React, Angular, Tailwind et bien d'autres. Mon objectif est de concevoir des sites web innovants et fonctionnels qui répondent aux besoins de mes clients tout en privilégiant une esthétique soignée. Mon portfolio est le reflet de mon engagement envers l'excellence dans le monde du développement web.
//                         </p>

//                         {/* Conteneur pour les compteurs */}
//                         <div className='flex flex-row justify-around items-center w-full'>
//                             {/* Compteur Années d'expérience */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={4} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Années d'expérience</p>
//                             </div>

//                             {/* Compteur Projets complétés */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={120} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Projets complétés</p>
//                             </div>

//                             {/* Compteur Clients satisfaits */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={15} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Clients satisfaits</p>
//                             </div>
//                         </div>
//                         <motion.div
//                             variants={fadeIn("up", 0.8)}
//                             initial="hidden"
//                             whileInView="show"
//                             viewport={{ once: false, amount: 0.7 }}
//                             className='flex gap-x-6 xxs:gap-x-4 items-center'
//                         >
//                             <Link to="contact" spy={true} smooth={true} offset={-70} duration={500}
//                                 className="btnBase4 btn-lg lg:btn-lg md:btn-md sm:btn-sm xs:btn-xs xxs:btn-xxs xxxs:btn-xxxs xxxxs:btn-xxxs flex items-center justify-center"
//                             >
//                                 Contactez-moi
//                             </Link>
//                             <a href="https://www.youtube.com/channel/UCQ6Zr5qXWZqkXZ5r9ZbQY0w" target="_blank" rel="noreferrer"
//                                 className="font-bold text-2xl text-[#F5981D] hover:text-[#875410] transition duration-300 ease-in-out"
//                             >
//                                 Mon Portfolio
//                             </a>
//                         </motion.div>
//                     </motion.div>
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default About;




// import React, { useRef, useEffect } from 'react';
// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { useInView } from 'react-intersection-observer';
// import CountUp from 'react-countup';
// import { motion } from 'framer-motion';
// import { fadeIn } from '../variants';
// import { Link } from 'react-scroll';
// import aboutHorizontalScrollImage from '../img/2078BurningChicagoOrange03.png';

// gsap.registerPlugin(ScrollTrigger);

// const About = () => {

//     const horizontalRef = useRef(null);
//     const { ref, inView } = useInView({ threshold: 0.1 });

//     useEffect(() => {
//         if (horizontalRef.current) {
//             let width = horizontalRef.current.offsetWidth; // la largeur du contenu que vous voulez faire défiler horizontalement

//             ScrollTrigger.create({
//                 trigger: horizontalRef.current,
//                 start: 'top top', // commence quand le haut du trigger touche le haut du viewport
//                 end: width, // finit après une distance égale à la largeur du contenu
//                 pin: true, // épingler le trigger
//                 scrub: true, // lisse l'animation pendant le défilement
//                 horizontal: true, // active le défilement horizontal
//                 pinSpacing: false, // désactive l'espacement de l'épingle
//                 // Vous pouvez ajuster la valeur de l'anticipation pour des performances meilleures ou différentes
//                 anticipatePin: 1
//             });

//             // Animation pour déplacer le contenu horizontalement
//             gsap.to(horizontalRef.current, {
//                 x: () => -(width - window.innerWidth), // déplace le contenu horizontalement de la largeur du contenu moins la largeur de la fenêtre
//                 ease: 'none',
//                 scrollTrigger: {
//                     trigger: horizontalRef.current,
//                     start: 'top top',
//                     end: width,
//                     scrub: true,
//                     horizontal: true,
//                     pin: true,
//                     pinSpacing: false,
//                     anticipatePin: 1,
//                 },
//             });
//         }
//     }, []);


//     return (
//         <section className="section h-auto bg-site1 bg-cover mt-[-335px] xxs:mt-[-265px]" id='about' ref={horizontalRef}>
//             <div className='flex flex-row justify-around items-center'
//                 style={{
//                     display: 'flex',
//                     textAlign: 'center',
//                     justifyContent: 'center',
//                     alignContent: 'center',
//                     backgroundImage: `url(${aboutHorizontalScrollImage})`,
//                     backgroundSize: 'cover',
//                     backgroundPosition: 'right center',
//                     backgroundRepeat: 'no-repeat',
//                     width: '100%', // Full width
//                     height: '50vh',
//                     opacity: '0.5',
//                     border: '3px solid #F39C2B'
//                 }}
//             >
//                 <h2 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-white-500'>Test</h2>
//             </div>
//             <div className='container mx-auto' style={{ display: 'flex', width: '100%', minWidth: '50vw', marginLeft: '-35vw', marginTop: '-20vh' }}>
//                 <div className='flex lg:flex-row flex-col justify-between items-center lg:items-start gap-10 lg:gap-20 h-full'>
//                     {/* Contenu de gauche (image ou autre) */}
//                     <motion.div
//                         variants={fadeIn("left", 0.5)}
//                         initial="hidden"
//                         animate="show"
//                         className='flex-1 flex flex-col gap-y-6 items-start'
//                     >
//                         {/* Insérez ici le contenu de gauche si nécessaire */}
//                     </motion.div>

//                     {/* Contenu de droite (textes et compteurs) */}
//                     <motion.div
//                         variants={fadeIn("right", 0.5)}
//                         initial="hidden"
//                         animate="show"
//                         className='flex-1 flex flex-col gap-y-6 items-start'
//                     >
//                         {/* Titre, sous-titre et paragraphe */}
//                         <h2 className='text-5xl 2xl:text-4xl xl:text-4xl lg:text-3xl md:text-3xl sm:text-3xl xs:text-2xl xxs:text-2xl xxxs:text-2xl xxxxs:text-xl xxxxs:ml-10  font-bold text-brown-700 uppercase mb-8 xxs:mb-16 xxxs:mb-12' style={{ color: '#F5981D', fontWeight: '700', textTransform: 'uppercase', height: '55vh', paddingTop: '32rem' }}>À propos de moi</h2>
//                         <h3 className='text-xl xxs:text-lg xxxs:text-base xxxs:text-base xxxxs:text-base font-bold text-orange-500 uppercase mb-4 lg:mb-8 xxs:mt-6 leading-tight sm:leading-snug md:leading-normal lg:leading-relaxed xxxxs:ml-10'>Je suis un développeur web fullstack freelance avec 4 années d'expérience.</h3>
//                         <p className='mb-6' style={{ backgroundColor: 'rgba(0,0,0, 0.5)', padding: '2rem' }}>
//                             En tant que développeur web fullstack freelance, j'ai acquis une solide expérience au cours de ces deux dernières années. Mon parcours m'a permis de maîtriser les aspects techniques et esthétiques du développement web. Je suis passionné par la création d'expériences utilisateur exceptionnelles en combinant mon expertise en HTML, CSS, JavaScript et d'autres technologies comme React, Angular, Tailwind et bien d'autres. Mon objectif est de concevoir des sites web innovants et fonctionnels qui répondent aux besoins de mes clients tout en privilégiant une esthétique soignée. Mon portfolio est le reflet de mon engagement envers l'excellence dans le monde du développement web.
//                         </p>

//                         {/* Conteneur pour les compteurs */}
//                         <div className='flex flex-row justify-around items-center w-full'>
//                             {/* Compteur Années d'expérience */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={4} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Années d'expérience</p>
//                             </div>

//                             {/* Compteur Projets complétés */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={120} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Projets complétés</p>
//                             </div>

//                             {/* Compteur Clients satisfaits */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={15} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Clients satisfaits</p>
//                             </div>
//                         </div>
//                         <motion.div
//                             variants={fadeIn("up", 0.8)}
//                             initial="hidden"
//                             whileInView="show"
//                             viewport={{ once: false, amount: 0.7 }}
//                             className='flex gap-x-6 xxs:gap-x-4 items-center'
//                         >
//                             <Link to="contact" spy={true} smooth={true} offset={-70} duration={500}
//                                 className="btnBase4 btn-lg lg:btn-lg md:btn-md sm:btn-sm xs:btn-xs xxs:btn-xxs xxxs:btn-xxxs xxxxs:btn-xxxs flex items-center justify-center"
//                             >
//                                 Contactez-moi
//                             </Link>
//                             <a href="https://www.youtube.com/channel/UCQ6Zr5qXWZqkXZ5r9ZbQY0w" target="_blank" rel="noreferrer"
//                                 className="font-bold text-2xl text-[#F5981D] hover:text-[#875410] transition duration-300 ease-in-out"
//                             >
//                                 Mon Portfolio
//                             </a>
//                         </motion.div>
//                     </motion.div>
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default About;



// import React, { useRef, useEffect } from 'react';
// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { useInView } from 'react-intersection-observer';
// import CountUp from 'react-countup';
// import { motion } from 'framer-motion';
// import { fadeIn } from '../variants';
// import { Link } from 'react-scroll';
// import aboutHorizontalScrollImage from '../img/2078BurningChicagoOrange03.png';

// gsap.registerPlugin(ScrollTrigger);

// const About = () => {

//     const { ref, inView } = useInView({ threshold: 0.1 });
//     const parallaxRef = useRef();

//     useEffect(() => {
//         if (parallaxRef.current) {
//             const horizontalWidth = `${parallaxRef.current.offsetWidth}px`; // Assurez-vous que cela correspond à la largeur de votre contenu

//             ScrollTrigger.create({
//                 trigger: parallaxRef.current,
//                 start: 'top top',
//                 end: `+=${horizontalWidth}`,
//                 pin: true,
//                 pinSpacing: false,
//                 scrub: true
//             });

//             gsap.fromTo(parallaxRef.current,
//                 { backgroundPosition: '0% center' },
//                 {
//                     backgroundPosition: `100% center`,
//                     ease: 'none',
//                     scrollTrigger: {
//                         trigger: parallaxRef.current,
//                         start: 'top top',
//                         end: `+=${horizontalWidth}`,
//                         scrub: true
//                     }
//                 });
//         }
//     }, []);

//     return (
//         <section className="section h-auto bg-site1 bg-cover mt:[-335] xxs:mt[-265]" id='about' ref={ref} >
//             <div className="horizontal-scroll-wrapper"
//                 style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}
//             >
//                 <div className="horizontal-scroll-content"
//                     ref={parallaxRef}
//                     style={{
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         textAlign: 'center',
//                         width: '40vw',
//                         height: '50vh',
//                         marginLeft: '10vw',
//                         backgroundImage: `url(${aboutHorizontalScrollImage})`,
//                         backgroundSize: 'cover',
//                         backgroundRepeat: 'no-repeat',
//                         backgroundPosition: 'right center',
//                         opacity: '0.5', // Opacity style ajouté
//                         border: '3px solid #F39C2B', // Border style ajouté
//                     }}
//                     // style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignContent: 'center', backgroundImage: `url(${aboutHorizontalScrollImage})`, backgroundSize: 'cover', backgroundPosition: 'right center', backgroundRepeat: 'no-repeat', width: '40vw', marginLeft: '10vw', height: '50vh', opacity: '0.5', border: '3px solid #F39C2B' }}
//                 >
//                     <h2 className='text-5xl text-white font-semibold'>Test</h2>
//                 </div>
//             </div>
//             <div className='container mx-auto' style={{ display: 'flex', width: '100%', minWidth: '50vw', marginLeft: '-35vw', marginTop: '-20vh' }}>
//                 <div className='flex lg:flex-row flex-col justify-between items-center lg:items-start gap-10 lg:gap-20 h-full'>
//                     {/* Contenu de gauche (image ou autre) */}
//                     <motion.div
//                         variants={fadeIn("left", 0.5)}
//                         initial="hidden"
//                         animate="show"
//                         className='flex-1 flex flex-col gap-y-6 items-start'
//                     >
//                         {/* Insérez ici le contenu de gauche si nécessaire */}
//                     </motion.div>

//                     {/* Contenu de droite (textes et compteurs) */}
//                     <motion.div
//                         variants={fadeIn("right", 0.5)}
//                         initial="hidden"
//                         animate="show"
//                         className='flex-1 flex flex-col gap-y-6 items-start'
//                     >
//                         {/* Titre, sous-titre et paragraphe */}
//                         <h2 className='text-5xl 2xl:text-4xl xl:text-4xl lg:text-3xl md:text-3xl sm:text-3xl xs:text-2xl xxs:text-2xl xxxs:text-2xl xxxxs:text-xl xxxxs:ml-10  font-bold text-brown-700 uppercase mb-8 xxs:mb-16 xxxs:mb-12' style={{ color: '#F5981D', fontWeight: '700', textTransform: 'uppercase', height: '55vh', paddingTop: '32rem' }}>À propos de moi</h2>
//                         <h3 className='text-xl xxs:text-lg xxxs:text-base xxxs:text-base xxxxs:text-base font-bold text-orange-500 uppercase mb-4 lg:mb-8 xxs:mt-6 leading-tight sm:leading-snug md:leading-normal lg:leading-relaxed xxxxs:ml-10'>Je suis un développeur web fullstack freelance avec 4 années d'expérience.</h3>
//                         <p className='mb-6' style={{ backgroundColor: 'rgba(0,0,0, 0.5)', padding: '2rem' }}>
//                             En tant que développeur web fullstack freelance, j'ai acquis une solide expérience au cours de ces deux dernières années. Mon parcours m'a permis de maîtriser les aspects techniques et esthétiques du développement web. Je suis passionné par la création d'expériences utilisateur exceptionnelles en combinant mon expertise en HTML, CSS, JavaScript et d'autres technologies comme React, Angular, Tailwind et bien d'autres. Mon objectif est de concevoir des sites web innovants et fonctionnels qui répondent aux besoins de mes clients tout en privilégiant une esthétique soignée. Mon portfolio est le reflet de mon engagement envers l'excellence dans le monde du développement web.
//                         </p>

//                         {/* Conteneur pour les compteurs */}
//                         <div className='flex flex-row justify-around items-center w-full'>
//                             {/* Compteur Années d'expérience */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={4} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Années d'expérience</p>
//                             </div>

//                             {/* Compteur Projets complétés */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={120} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Projets complétés</p>
//                             </div>

//                             {/* Compteur Clients satisfaits */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={15} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Clients satisfaits</p>
//                             </div>
//                         </div>
//                         <motion.div
//                             variants={fadeIn("up", 0.8)}
//                             initial="hidden"
//                             whileInView="show"
//                             viewport={{ once: false, amount: 0.7 }}
//                             className='flex gap-x-6 xxs:gap-x-4 items-center'
//                         >
//                             <Link to="contact" spy={true} smooth={true} offset={-70} duration={500}
//                                 className="btnBase4 btn-lg lg:btn-lg md:btn-md sm:btn-sm xs:btn-xs xxs:btn-xxs xxxs:btn-xxxs xxxxs:btn-xxxs flex items-center justify-center"
//                             >
//                                 Contactez-moi
//                             </Link>
//                             <a href="https://www.youtube.com/channel/UCQ6Zr5qXWZqkXZ5r9ZbQY0w" target="_blank" rel="noreferrer"
//                                 className="font-bold text-2xl text-[#F5981D] hover:text-[#875410] transition duration-300 ease-in-out"
//                             >
//                                 Mon Portfolio
//                             </a>
//                         </motion.div>
//                     </motion.div>
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default About;





// import React, { useRef, useEffect } from 'react';
// import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { useInView } from 'react-intersection-observer';
// import CountUp from 'react-countup';
// import { motion } from 'framer-motion';
// import { fadeIn } from '../variants';
// import { Link } from 'react-scroll';
// import aboutHorizontalScrollImage from '../img/2078BurningChicagoOrange03.png';

// gsap.registerPlugin(ScrollTrigger);

// const About = () => {

//     const { ref, inView } = useInView({ threshold: 0.1 });
//     const horizontalScrollRef = useRef();

//     useEffect(() => {
//         if (horizontalScrollRef.current) {
//             const horizontalWidth = '100%'; // La largeur totale de votre contenu horizontal

//             // Créer l'effet de défilement horizontal
//             gsap.to(horizontalScrollRef.current, {
//                 backgroundPosition: '100% center',
//                 ease: 'none',
//                 scrollTrigger: {
//                     trigger: horizontalScrollRef.current,
//                     start: 'top top',
//                     end: () => `+=${document.documentElement.clientWidth}`,
//                     scrub: true,
//                     pin: true,
//                     pinSpacing: false,
//                 },
//             });
//         }
//     }, []);
      

//     return (
//         <section className="section h-auto bg-site1 bg-cover mt:[-335] xxs:mt[-265]" id='about' ref={ref} >
//             <div
//                 ref={horizontalScrollRef}
//                 className='horizontal-scroll'
//                 style={{ width: '100vw', height: '100vh' }}
//             >
//                 <div className='flex flex-row justify-around items-center'
//                     ref={horizontalScrollRef}
//                     style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignContent: 'center', backgroundImage: `url(${aboutHorizontalScrollImage})`, backgroundSize: 'cover', backgroundPosition: 'right center', backgroundRepeat: 'no-repeat', width: '40vw', marginLeft: '10vw', height: '50vh', opacity: '0.5', border: '3px solid #F39C2B' }}
//                 >
//                     <h2 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-white-500' style={{ opacity: '1.0' }}>Test</h2>
//                 </div>
//             </div>
//             <div id="aboutEnd"></div>
//             <div className='container mx-auto' style={{ display: 'flex', width: '100%', minWidth: '50vw', marginLeft: '-35vw', marginTop: '-20vh' }}>
//                 <div className='flex lg:flex-row flex-col justify-between items-center lg:items-start gap-10 lg:gap-20 h-full'>
//                     {/* Contenu de gauche (image ou autre) */}
//                     <motion.div
//                         variants={fadeIn("left", 0.5)}
//                         initial="hidden"
//                         animate="show"
//                         className='flex-1 flex flex-col gap-y-6 items-start'
//                     >
//                         {/* Insérez ici le contenu de gauche si nécessaire */}
//                     </motion.div>

//                     {/* Contenu de droite (textes et compteurs) */}
//                     <motion.div
//                         variants={fadeIn("right", 0.5)}
//                         initial="hidden"
//                         animate="show"
//                         className='flex-1 flex flex-col gap-y-6 items-start'
//                     >
//                         {/* Titre, sous-titre et paragraphe */}
//                         <h2 className='text-5xl 2xl:text-4xl xl:text-4xl lg:text-3xl md:text-3xl sm:text-3xl xs:text-2xl xxs:text-2xl xxxs:text-2xl xxxxs:text-xl xxxxs:ml-10  font-bold text-brown-700 uppercase mb-8 xxs:mb-16 xxxs:mb-12' style={{ color: '#F5981D', fontWeight: '700', textTransform: 'uppercase', height: '55vh', paddingTop: '32rem' }}>À propos de moi</h2>
//                         <h3 className='text-xl xxs:text-lg xxxs:text-base xxxs:text-base xxxxs:text-base font-bold text-orange-500 uppercase mb-4 lg:mb-8 xxs:mt-6 leading-tight sm:leading-snug md:leading-normal lg:leading-relaxed xxxxs:ml-10'>Je suis un développeur web fullstack freelance avec 4 années d'expérience.</h3>
//                         <p className='mb-6' style={{ backgroundColor: 'rgba(0,0,0, 0.5)', padding: '2rem' }}>
//                             En tant que développeur web fullstack freelance, j'ai acquis une solide expérience au cours de ces deux dernières années. Mon parcours m'a permis de maîtriser les aspects techniques et esthétiques du développement web. Je suis passionné par la création d'expériences utilisateur exceptionnelles en combinant mon expertise en HTML, CSS, JavaScript et d'autres technologies comme React, Angular, Tailwind et bien d'autres. Mon objectif est de concevoir des sites web innovants et fonctionnels qui répondent aux besoins de mes clients tout en privilégiant une esthétique soignée. Mon portfolio est le reflet de mon engagement envers l'excellence dans le monde du développement web.
//                         </p>

//                         {/* Conteneur pour les compteurs */}
//                         <div className='flex flex-row justify-around items-center w-full'>
//                             {/* Compteur Années d'expérience */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={4} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Années d'expérience</p>
//                             </div>

//                             {/* Compteur Projets complétés */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={120} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Projets complétés</p>
//                             </div>

//                             {/* Compteur Clients satisfaits */}
//                             <div className='text-center'>
//                                 <h3 className='text-5xl xs:text-3xl xxs:text-2xl xxxs:text-xl font-semibold text-orange-500'>
//                                     {inView ? <CountUp end={15} duration={3.5} /> : '0'}
//                                 </h3>
//                                 <p className='text-2xl xs:text-xl xxs:text-lg xxxs:text-sm text-brown-700'>Clients satisfaits</p>
//                             </div>
//                         </div>
//                         <motion.div
//                             variants={fadeIn("up", 0.8)}
//                             initial="hidden"
//                             whileInView="show"
//                             viewport={{ once: false, amount: 0.7 }}
//                             className='flex gap-x-6 xxs:gap-x-4 items-center'
//                         >
//                             <Link to="contact" spy={true} smooth={true} offset={-70} duration={500}
//                                 className="btnBase4 btn-lg lg:btn-lg md:btn-md sm:btn-sm xs:btn-xs xxs:btn-xxs xxxs:btn-xxxs xxxxs:btn-xxxs flex items-center justify-center"
//                             >
//                                 Contactez-moi
//                             </Link>
//                             <a href="https://www.youtube.com/channel/UCQ6Zr5qXWZqkXZ5r9ZbQY0w" target="_blank" rel="noreferrer"
//                                 className="font-bold text-2xl text-[#F5981D] hover:text-[#875410] transition duration-300 ease-in-out"
//                             >
//                                 Mon Portfolio
//                             </a>
//                         </motion.div>
//                     </motion.div>
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default About;


















