
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import projectImage01 from '../img/projectImage01.png';
import projectImage02 from '../img/projectImage02.png';
import projectImage03 from '../img/projectImage03.png';
import projectImage04 from '../img/React-icon.svg.png';
import projectImage05 from '../img/Wordpress_Blue_logo.png';
import projectImage06 from '../img/xdlogo-icon.png';

const Project = () => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        // Vérifier immédiatement et ensuite à chaque redimensionnement
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        // Nettoyage de l'écouteur d'événements
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    // Utilisez une div normale au lieu de motion.div si isMobile est true
    const ResponsiveDiv = isMobile ? 'div' : motion.div;


    return (
        <section className="section min-h-screen lg:min-h-[85vh]" id='project'>
            <div className='container mx-auto px-4 lg:px-0'>
                <div className='flex flex-col lg:flex-row gap-10 lg:gap-20 px-5'>
                    <ResponsiveDiv
                        variants={fadeIn("right", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className="flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0"
                    >
                        <div>
                            <h2 className='h2 leading-tight'>
                                Mes dernières <br /> réalisations
                            </h2>
                            <p className='max-w-sm mb-16' style={{ fontSize: '0.9rem' }}>
                                Découvrez mes derniers projets de développement web, d'UI/UX design et de marketing numérique. Vous pouvez également consulter mes divers prototypes et expérimentations.
                            </p>
                            <button className="btnBase3 btn-sm">Voir mes projets</button>
                        </div>
                        <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
                            <a href="https://moviefrenzy.lucabolduc-courtier.com/" target="_blank" rel="noopener noreferrer">
                                <div className='groupe-hover bg-black/70 z-40 w-fill h-full absolute transition-all duration-300 cursor-pointer'>
                                </div>

                                <img src={projectImage01} alt='Project Image' className='group-hover:scale-125 transition-all duration-500 cursor-pointer' />

                                <div className='absolute -bottom-fill left-12 group-hover:bottom-24 transition-all duration-500 z-30 devTitre' style={{ color: '#F08927', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                                    <span className='lg:text-3xl md:text-2xl xs:text-lg xxxxs:text-sm xxxxxs:text-xxs'>Développement Full-Stack</span>

                                </div>
                                <div className='absolute -bottom-fill left-12 group-hover:bottom-12 transition-all duration-500 z-50 titreProjet' style={{ color: '#F08927', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                                    <span className='font-primary text-white lg:text-4xl md:text-3xl xs:text-xl xxxxs:text-base xxxxxs:text-tiny'>MovieFrenzy</span>
                                    <img src={projectImage04} alt='React Logo' className='inline-block w-6 h-6 lg:w-12 lg:h-12 ml-2' />
                                </div>
                            </a>
                        </div>
                    </ResponsiveDiv>

                    <ResponsiveDiv
                        variants={fadeIn("left", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        className='flex-1 flex flex-col gap-y-9'
                    >
                        <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
                            <a href="https://lucabolduc-courtier.com/" target="_blank" rel="noopener noreferrer">
                                <div className='groupe-hover bg-black/70 z-40 w-fill h-full absolute transition-all duration-300 cursor-pointer'>
                                </div>
                                <img src={projectImage02} alt='Project Image' className='group-hover:scale-125 transition-all duration-500 cursor-pointer' />
                                <div className='absolute -bottom-fill left-12 group-hover:bottom-24 transition-all duration-500 z-50 devTitre' style={{ color: '#F08927', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                                    <span className='lg:text-3xl md:text-2xl xs:text-lg xxxxs:text-sm xxxxxs:text-xxs devTitre'>Développement Front-End</span>
                                </div>
                                <div className='absolute -bottom-fill left-12 group-hover:bottom-12 transition-all duration-500 z-50' style={{ color: '#F08927', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                                    <span className='font-primary text-white lg:text-4xl md:text-3xl xs:text-xl xxxxs:text-base xxxxxs:text-tiny'>Site Wordpress</span>
                                    <img src={projectImage05} alt='React Logo' className='inline-block w-6 h-6 lg:w-12 lg:h-12 ml-2' />
                                </div>
                            </a>
                        </div>

                        <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl mt-5'>
                            <a href="https://xd.adobe.com/view/c7dff72b-2bc5-4c93-8e65-bb223f7cdcc6-928c/" target="_blank" rel="noopener noreferrer">
                                <div className='groupe-hover bg-black/70 z-40 w-fill h-full absolute transition-all duration-300 cursor-pointer'>
                                </div>
                                <img src={projectImage03} alt='Project Image' className='group-hover:scale-125 transition-all duration-500 cursor-pointer' />
                                <div className='absolute -bottom-fill left-12 group-hover:bottom-24 transition-all duration-500 z-50 devTitre' style={{ color: '#F08927', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                                    <span className='font-primary text-orange-500 lg:text-3xl md:text-2xl xs:text-lg xxxxs:text-sm xxxxxs:text-xxs devTitre'>Développement Mobile</span>
                                </div>
                                <div className='absolute -bottom-fill left-12 group-hover:bottom-12 transition-all duration-500 z-50' style={{ color: '#F08927', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                                    <span className='font-primary text-white lg:text-4xl md:text-3xl xs:text-xl xxxxs:text-base xxxxxs:text-tiny'>Maquette Prototype</span>
                                    <img src={projectImage06} alt='React Logo' className='inline-block w-6 h-6 lg:w-12 lg:h-12 ml-2' />
                                </div>
                            </a>
                        </div>
                    </ResponsiveDiv>
                </div>
            </div>
        </section>
    );
};

export default Project;


