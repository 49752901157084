import React from 'react'
import logo from '../img/killBeanAi01B.jpg';
import { Link } from 'react-scroll';

const Header = () => {
    return (
        <div className="py-6 full" id='header'>
            <div className="container mx-auto">
                <div className="flex justify-between items-center">
                    <a href="#">
                      <img src={logo} alt="Logo" className="w-[150px] sm:w-[90px] 2xl:scale-150 mt-10 2xl:ml-10" />
                    </a>
                    <Link to="contact" spy={true} smooth={true} offset={-70} duration={500}
                        className="btnBase4 btn-lg lg:btn-lg md:btn-md sm:btn-sm xs:btn-xs xxs:btn-xs xxxs:btn-xxs xxxxs:btn-xxxs flex items-center justify-center"
                    >
                        Contactez-moi
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Header;







