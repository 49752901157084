import React, { useState, useEffect } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import Testimonials from './Testimonials';

// Définition du composant ServiceItem
const ServiceItem = ({ name, desc, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="flex flex-col sm:flex-row items-center sm:items-start border-b border-white/20  gap-2 sm:gap-4">
      <div className='w-full sm:max-w-[476px]'>
        <h4 className='text-lg sm:text-xl font-semibold text-[#F5981D]'>
          {name}
        </h4>
        <p className='text-sm leading-tight mt-2'>
          {desc}
        </p>
      </div>
      <div className='flex justify-center sm:justify-end w-full sm:w-auto'>
        <div className='rounded-full bg-[#875410] w-9 h-9 flex items-center justify-center'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            filter: isHovered ? 'drop-shadow(0px 0px 12px rgba(245, 152, 29, 0.8))' : 'none',
            border: isHovered ? '2px solid #F5981D' : 'none',
            transition: 'all 0.3s ease'
          }}
        >
          <BsArrowRight color="#FFF" />
        </div>
        <a href='#' className='text-sm ml-2' style={{ alignSelf: 'center' }}>
          {link}
        </a>
      </div>
    </div>
  );
}

// Définition du composant Services
const Services = () => {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    // Vérifier immédiatement et ensuite à chaque redimensionnement
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    // Nettoyage de l'écouteur d'événements
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  // Utilisez une div normale au lieu de motion.div si isMobile est true
  const ResponsiveDiv = isMobile ? 'div' : motion.div;

  // Tableau des services
  const services = [
    {
      name: "UI/UX Design",
      desc: "Je conçois des expériences utilisateur intuitives et des interfaces élégantes, en mettant l'accent sur la facilité d'utilisation et l'engagement de l'utilisateur. Mon approche est centrée sur l'humain, en privilégiant la recherche et les tests utilisateur pour créer des solutions de design qui répondent réellement aux besoins et préférences de votre public cible.",
      link: "Pour en savoir plus",
    },
    {
      name: "Développement Fullstack",
      desc: "Maîtrisant le spectre complet du développement web, je crée des applications dynamiques et performantes. De la gestion des bases de données à la programmation côté serveur et l'intégration côté client, je m'assure d'une architecture robuste et d'une expérience utilisateur sans faille.",
      link: "Pour en savoir plus",
    },
    {
      name: "Développement mobile",
      desc: "Je développe des applications mobiles réactives et bien conçues pour Android et iOS. En utilisant des technologies modernes, je garantis des applications non seulement magnifiques mais aussi rapides, efficaces et agréables à utiliser sur tous les appareils mobiles.",
      link: "Pour en savoir plus",
    },
    {
      name: "Marketing numérique",
      desc: "J'élargis la portée de votre marque en ligne grâce à des stratégies de marketing numérique ciblées. De l'optimisation pour les moteurs de recherche (SEO) aux campagnes publicitaires payantes, je m'engage à augmenter votre visibilité et à stimuler votre croissance sur le marché digital.",
      link: "Pour en savoir plus",
    }
  ];

  return (
    <section className="section sectionService h-screen mt-32 xxxs:mt-84 xxs:mt-48 xs:mt-48 sm:mt-36 md:mt-36 lg:mt-32 xl:mt-24 2xl:mt-16" id='services' >
      <div className="container mx-auto">
        <div className='flex flex-col lg:flex-row'>
          <ResponsiveDiv
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0 px-4 lg:px-0"
          >
            <h2 className="h2 mb-6" style={{ color: '#875410', fontSize: '2.4rem', fontWeight: '700' }}>Qui suis-je?</h2>
            <h3 className="h3 max-w-[455px] mb-16">Je suis un développeur web fullstack freelance avec 4 années d'expérience.</h3>
            <button className="btnBase3 btn-sm mx-auto">Voir mes projets</button>
          </ResponsiveDiv>
          <ResponsiveDiv
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            animate="show"
            viewport={{ once: false, amount: 0.3 }}
          >
            {services.map((item, idx) => (
              <ServiceItem key={idx} name={item.name} desc={item.desc} link={item.link} />
            ))}
          </ResponsiveDiv>
        </div>
        <Testimonials />
      </div>
    </section>
  );
};

export default Services;




