
import React from 'react';
import { motion } from 'framer-motion';
import bottomTestimonial_Bg from '../img/OrangeVolcanicLandscapeVeryLarge01.png';

const Testimonials = () => {

    return (

        <section className="testimonials-section bg-site1">
        <div className="vertical-marquee">
            <div className="vertical-marquee__blurred-circle">
            </div>
            <div className="vertical-marquee__blurred vertical-marquee__blurred--top">
                <h2 className="text-2xl font-bold text-center text-white testimoniatitre mb-24">Ce que disent les gens de moi</h2>
            </div>
            <div className="vertical-marquee__blurred vertical-marquee__blurred--bottom" 
              style={{ backgroundImage: `url(${bottomTestimonial_Bg})`, backgroundSize: 'cover', backgroundPosition: 'center'  }}
            >   
            </div>
            <div className="vertical-marquee__slider">
                <div className="vertical-marquee__panel vertical-marquee__panel-1">
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>Le travail de développement réalisé a dépassé toutes mes attentes. Non seulement le projet a été livré à temps, mais la qualité était au rendez-vous. Je recommande vivement pour tout projet web complexe!<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Alexandre Dupont</p>
                        <span className='card-author_post'>CEO de Tech Innovations</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>La création de notre site e-commerce a été une révélation. L'expertise technique et la capacité à saisir l'esprit de notre épicerie de quartier ont été exceptionnelles. Un service client hors pair !<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">
                          Marie-Anne Lefebvre
                        </p>
                        <span className='card-author_post'>Propriétaire de L'Épicerie du Coin</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>En tant que designer, j'ai travaillé avec de nombreux développeurs, mais l'expérience avec ce professionnel a été de loin la meilleure. Communication fluide, excellente compréhension du design et mise en œuvre parfaite.<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Sophie Laurent.</p>
                        <span className='card-author_post'>Designer UX/UI Freelance</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>La capacité à transformer une idée en une application fonctionnelle et esthétiquement plaisante est impressionnante. Le support après lancement est également remarquable. Un vrai partenaire dans le développement!<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Luc Martin.</p>
                        <span className='card-author_post'>Fondateur de StartUp Now</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>L'approche personnalisée et le souci de répondre précisément à nos besoins font vraiment la différence. Le site est rapide, sécurisé et a déjà boosté notre clientèle.<span className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Thomas Girard</p>
                        <span className='card-author_post'>Gérant de Bistro Le Parisien</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>Un travail remarquable sur notre application mobile. L'interface est intuitive et nos clients l'adorent. Merci pour votre professionnalisme et votre créativité.<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Léa Dupuis.</p>
                        <span className='card-author_post'>Co-fondatrice de HealthyApp</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>Notre collaboration a permis de moderniser notre image en ligne avec un site web réactif et attrayant. Les conseils et l'assistance tout au long du projet ont été très précieux.<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Camille Bernard</p>
                        <span className='card-author_post'>Responsable Communication chez ÉcoSolutions</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>Chaque détail a été pris en compte pour notre campagne de marketing numérique. Les résultats sont impressionnants et le retour sur investissement est immédiat.<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">
                           Olivier Petit
                        </p>
                        <span className='card-author_post'>Directeur de Stratégie chez MarketPlus</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>La refonte de notre système de réservation en ligne était un défi complexe, relevé avec brio. La nouvelle interface a grandement facilité la gestion de nos clients.<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Jean-Michel Roux</p>
                        <span className='card-author_post'>CEO de Voyager</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>Le site web créé pour notre salon de coiffure est juste magnifique. Il reflète parfaitement l'ambiance que nous voulions transmettre. Nos clients adorent pouvoir prendre rendez-vous en ligne !<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Claire Fontaine</p>
                        <span className='card-author_post'>Propriétaire du Salon Belle Chevelure</span>
                    </div>
                </div>

                <div className="vertical-marquee__panel vertical-marquee__panel-2">
                <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>Le travail de développement réalisé a dépassé toutes mes attentes. Non seulement le projet a été livré à temps, mais la qualité était au rendez-vous. Je recommande vivement pour tout projet web complexe!<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Alexandre Dupont</p>
                        <span className='card-author_post'>CEO de Tech Innovations</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>La création de notre site e-commerce a été une révélation. L'expertise technique et la capacité à saisir l'esprit de notre épicerie de quartier ont été exceptionnelles. Un service client hors pair !<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">
                          Marie-Anne Lefebvre
                        </p>
                        <span className='card-author_post'>Propriétaire de L'Épicerie du Coin</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>En tant que designer, j'ai travaillé avec de nombreux développeurs, mais l'expérience avec ce professionnel a été de loin la meilleure. Communication fluide, excellente compréhension du design et mise en œuvre parfaite.<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Sophie Laurent.</p>
                        <span className='card-author_post'>Designer UX/UI Freelance</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>La capacité à transformer une idée en une application fonctionnelle et esthétiquement plaisante est impressionnante. Le support après lancement est également remarquable. Un vrai partenaire dans le développement!<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Luc Martin.</p>
                        <span className='card-author_post'>Fondateur de StartUp Now</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>L'approche personnalisée et le souci de répondre précisément à nos besoins font vraiment la différence. Le site est rapide, sécurisé et a déjà boosté notre clientèle.<span className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Thomas Girard</p>
                        <span className='card-author_post'>Gérant de Bistro Le Parisien</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>Un travail remarquable sur notre application mobile. L'interface est intuitive et nos clients l'adorent. Merci pour votre professionnalisme et votre créativité.<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Léa Dupuis.</p>
                        <span className='card-author_post'>Co-fondatrice de HealthyApp</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>Notre collaboration a permis de moderniser notre image en ligne avec un site web réactif et attrayant. Les conseils et l'assistance tout au long du projet ont été très précieux.<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Camille Bernard</p>
                        <span className='card-author_post'>Responsable Communication chez ÉcoSolutions</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>Chaque détail a été pris en compte pour notre campagne de marketing numérique. Les résultats sont impressionnants et le retour sur investissement est immédiat.<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">
                           Olivier Petit
                        </p>
                        <span className='card-author_post'>Directeur de Stratégie chez MarketPlus</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>La refonte de notre système de réservation en ligne était un défi complexe, relevé avec brio. La nouvelle interface a grandement facilité la gestion de nos clients.<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Jean-Michel Roux</p>
                        <span className='card-author_post'>CEO de Voyager</span>
                    </div>
                    <div className="vertical-marquee__card">
                        <p className="vertical-marquee__card-text">
                            <span className="vertical-marquee__green-quotes">"</span>Le site web créé pour notre salon de coiffure est juste magnifique. Il reflète parfaitement l'ambiance que nous voulions transmettre. Nos clients adorent pouvoir prendre rendez-vous en ligne !<span
                                className="vertical-marquee__green-quotes">"</span>
                        </p>
                        <p className="vertical-marquee__card-author">Claire Fontaine</p>
                        <span className='card-author_post'>Propriétaire du Salon Belle Chevelure</span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    );
};

export default Testimonials;


