import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { TypeAnimation } from 'react-type-animation';
import { FaYoutube } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaGithub } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import myImage from '../img/meHornyBgOrange.jpg';
import bgMyImage from '../img/bg1-6orangeV2Brown.png';
import { gsap } from "gsap-trial";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'react-scroll';
import vidMyImage from '../img/TranspsarentMeHornVocalise.mp4';

gsap.registerPlugin(ScrollTrigger);

const Main = () => {

    const ref = useRef();
    const [hover, setHover] = useState(false);

    useEffect(() => {
        const q = gsap.utils.selector(ref); // Utilitaire pour sélectionner les enfants du ref

        gsap.registerPlugin(ScrollTrigger);
        const chars = q(".char"); // Sélectionne tous les éléments avec la classe 'char'

        chars.forEach((char, i) => {
            gsap.fromTo(char,
                { autoAlpha: 0, y: 30 }, // Commence invisible et 30 pixels vers le bas
                {
                    duration: 0.6,
                    autoAlpha: 1,
                    y: 0,
                    ease: "back.out(3)", // Un peu d'élasticité pour l'effet de rebond
                    delay: i * 0.05, // Délai basé sur l'index de la lettre
                    scrollTrigger: {
                        trigger: ref.current,
                        start: "top center+=100", // Déclenche l'animation un peu avant que l'élément n'atteigne le centre du viewport
                        toggleActions: "play none none reverse", // Joue et inverse l'animation en fonction du scroll
                        once: true, // Exécute l'animation une seule fois
                    }
                }
            );
        });

        // ScrollTrigger pour réinitialiser et rejouer l'animation lorsqu'on revient à la section
        ScrollTrigger.create({
            trigger: ref.current,
            start: "top center",
            onEnterBack: () => {
                chars.forEach((char, i) => {
                    gsap.fromTo(char, { autoAlpha: 0, y: 30 }, { autoAlpha: 1, y: 0, delay: i * 0.05 });
                });
            },
        });

    }, []);

    const [isMobileLarge, setIsMobileLarge] = useState(window.innerWidth < 1401);

    useEffect(() => {
        // Vérifiez si window est défini
        if (typeof window !== 'undefined') {
            const handleResize = () => {
                setIsMobileLarge(window.innerWidth < 1401);
            };

            // Ajoutez l'écouteur d'événement
            window.addEventListener('resize', handleResize);

            // Déclenchez manuellement la fonction handleResize au montage pour définir l'état initial
            handleResize();

            // Supprimez l'écouteur d'événement lors du démontage
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);



    return (
        <section ref={ref} className="section min-h-[85vh] lg:min-h-[70vh] flex items-center bg-site bg-cover" id="home">
            <div className="container mx-auto lg:mt-28 mt-8">
                <div className="flex flex-col lg:flex-row gap-y-8 lg:gap-y-0">
                    <div className="flex-1 text-center font-secondary lg:text-left"
                        style={{
                            fontFamily: 'Montserrat',
                            color: '#F5981D',
                            textShadow: '0px 0px 10px rgba(245, 152, 29, 0.5)',
                            backgroundImage: isMobileLarge ? 'none' : `url(${bgMyImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'right center',
                            backgroundRepeat: 'no-repeat'
                        }}>
                        <motion.h1
                            variants={fadeIn("up", 0.3)}
                            initial="hidden"
                            animate="show"
                            viewport={{ once: false, amount: 0.7 }}
                            className="text-5xl sm:text-3xl font-bold leading-[0.8] lg:text-5xl">
                            PHILIPPE <span>BOLDUC</span>
                        </motion.h1>
                        <div className="text-3xl font-bold leading-[0.8] lg:text-6xl mt-10 mb-10">
                            {Array.from('Forgeron.Numérique').map((char, index) => (
                                <span key={index} className="char" style={{ display: 'inline-block', opacity: 0, color: '#F7761F' }}>
                                    {char}
                                </span>
                            ))}
                        </div>
                        <motion.div
                            variants={fadeIn("up", 0.5)}
                            initial="hidden"
                            animate="show"
                            viewport={{ once: false, amount: 0.7 }}
                            className="mb-6 mt-2 text-[36px] lg:text-[54px] uppercase leading-[1] font-semibold"
                        >
                            <span className="text-[#FFF] lg:text-[2rem] uppercase">Je suis un </span>
                            <TypeAnimation
                                sequence={[
                                    ' développeur full stack',
                                    2000,
                                    ' développeur mobile',
                                    2000,
                                    'Designer Web',
                                    2000,
                                ]}
                                speed={50}
                                className='font-bold text-2xl'
                                wrapper="span"
                                cursor={true}
                                repeat={Infinity}
                                style={{
                                    color: '#875410',
                                    fontSize: '2.5rem',
                                }}

                            />
                        </motion.div>
                        <motion.p
                            variants={fadeIn("up", 0.6)}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: false, amount: 0.7 }}
                            className="mb-8 max-w-md mx-auto lg:mx-0 sm:text-sm sm:px-4 sm:py-2"
                            style={{
                                color: '#FFF',
                                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                border: '2px solid #F5981D',
                                padding: '1rem',
                                borderRadius: '10px',
                                fontWeight: '200',
                                fontSize: '1.2rem',
                            }}
                        >
                            Je suis un développeur qui adore créer des applications Web et mobiles.  J'aime aussi créer des designs de haut niveau pour les interfaces de ces applications. Je suis un forgeron du Web maniant avec innovation le code et l'expérience design <span style={{ color: '#F7761F' }}>UX/UI</span> à votre service !
                        </motion.p>

                        <motion.div
                            variants={fadeIn("up", 0.8)}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: false, amount: 0.7 }}
                            className='flex max-w-max mx-auto lg:mx-0 items-center gap-x-6 mb-12'
                            style={{ paddingBottom: '2rem', paddingTop: '2rem' }}
                        >
                            <Link to="contact" spy={true} smooth={true} offset={-70} duration={500}
                                className="btnBase4 btn-lg lg:btn-lg md:btn-md sm:btn-sm xs:btn-xs xxs:btn-xs xxxs:btn-xxs xxxxs:btn-xxxs flex items-center justify-center"
                            >
                                Contactez-moi
                            </Link>
                            <a href="https://www.youtube.com/channel/UCQ6Zr5qXWZqkXZ5r9ZbQY0w" target="_blank" rel="noreferrer"
                                className="font-bold text-2xl text-[#F5981D] hover:text-[#875410] transition duration-300 ease-in-out"
                            >
                                Mon Portfolio
                            </a>
                        </motion.div>
                        <motion.div
                            variants={fadeIn("up", 0.9)}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: false, amount: 0.7 }}
                            className='flex max-w-max mx-auto lag:mx-0 items-center gap-x-4'
                        >
                            <a href="https://www.youtube.com/channel/UCgB19rBmQlRePNg1-fwfJpA" target="_blank" rel="noreferrer">
                                <FaYoutube className="text-4xl text-[#F5981D] hover:text-[#875410] transition duration-300 ease-in-out" />
                            </a>
                            <a href="https://www.linkedin.com/in/philippebolduc/" target="_blank" rel="noreferrer">
                                <FaLinkedin className="text-4xl text-[#F5981D] hover:text-[#875410] transition duration-300 ease-in-out" />
                            </a>
                            <a href="https://github.com/Philousk" target="_blank" rel="noreferrer">
                                <FaGithub className="text-4xl text-[#F5981D] hover:text-[#875410] transition duration-300 ease-in-out" />
                            </a>
                            <a href="https://www.facebook.com/philippe.bolduc.75/" target="_blank" rel="noreferrer">
                                <FaFacebook className="text-4xl text-[#F5981D] hover:text-[#875410] transition duration-300 ease-in-out" />
                            </a>
                        </motion.div>
                    </div>

                    <div
                        variants={fadeIn("down", 0.6)}
                        initial="hidden"
                        animate="show"
                        className='flex-1 max-w-[300px] lf:max-w-[460px] image-container'
                        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
                    >
                        <img src={myImage} alt="Philippe Bolduc" className={`rounded-xl image ${hover ? 'fade-out' : ''}`} />
                        {hover && (
                            <video className="video fade-in" src={vidMyImage} autoPlay loop />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Main;






