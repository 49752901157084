import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { sendMessageToFirestore } from '../firestoreService';
import formRoseauImage from '../img/orangeBrownRoseaux.png';
import { CardBody, CardContainer, CardItem } from "./3d-card.tsx";

// Fonction de validation pour mon formulaire
const validateContactForm = (nom, email, message) => {
    let errors = {};
    if (!nom.trim()) {
        errors.nom = "Le nom est requis.";
    }
    if (!email) {
        errors.email = "Le courriel est requis.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
        errors.email = "L'adresse courriel est invalide.";
    }
    if (!message.trim()) {
        errors.message = "Le message ne peut pas être vide.";
    }
    return errors;
};


const Contact = () => {

    // États locaux pour les champs du formulaire
    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});

    // Fonction pour gérer la soumission du formulaire
    const handleSubmit = (event) => {
        event.preventDefault();
        const formErrors = validateContactForm(nom, email, message);
        if (Object.keys(formErrors).length === 0) {
            sendMessageToFirestore(nom, email, message);
            setNom('');
            setEmail('');
            setMessage('');
        } else {
            setErrors(formErrors);
        }
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        // Vérifier immédiatement et ensuite à chaque redimensionnement
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        // Nettoyage de l'écouteur d'événements
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    // Utilisez une div normale au lieu de motion.div si isMobile est true
    const ResponsiveDiv = isMobile ? 'div' : motion.div;

    const [hover, setHover] = useState(false);

    return (
        <section className="section mt-12 bg-site1 bg-cover" id='contact'>
            <div className='container mx-auto'>
                <div className='flex flex-col lg:flex-row'>
                    <ResponsiveDiv className='flex-1 flex justify-start items-center'
                        variants={fadeIn("right", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                    >
                        <div>
                            <h4 className='text-xl uppercase font-mediun mb-6 tracking-wide' style={{ color: '#F08927' }}>Contactez-moi</h4>
                            <h2 className='text-4xl lg:text-6xl leading-none font-primary' style={{ backgroundColor: 'rgba(0,0,0, 0.5)', padding: '1rem' }}>
                                Travaillons <br /> ensemble.
                            </h2>
                        </div>
                    </ResponsiveDiv>
                    <ResponsiveDiv className='flex-1 lg:mt-0 mt-8 border rounded-2xl flex flex-col gap-y-5 pb-24 p-6 items-start'
                        variants={fadeIn("left", 0.3)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.7 }}
                        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
                        style={{
                            backgroundImage: hover ? `url(${formRoseauImage})` : 'none',
                            backgroundSize: 'calc(100% + 100rem) calc(100% + 120rem)',
                            backgroundPosition: 'center center',
                            backgroundRepeat: 'no-repeat',
                            position: 'relative',
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <form onSubmit={handleSubmit}>
                            <CardContainer>
                                <CardBody>
                                    <CardItem>
                                        <input
                                            name="nom"
                                            type="text"
                                            placeholder="Votre nom"
                                            value={nom}
                                            onChange={(e) => setNom(e.target.value)}
                                            className="bg-transparent border-b-2 border-orange-500 py-3 outline-none placeholder:text-white transition-all duration-300"
                                            style={{
                                              borderColor: '#F08927',
                                              borderWidth: isMobile ? '1px' : '2px',
                                              paddingLeft: isMobile ? '1rem' : '1rem',  // ou tout autre valeur selon votre design
                                              paddingRight: isMobile ? '1rem' : '0', // Ajustez en conséquence
                                              marginTop: '1rem',
                                              width: isMobile ? 'auto' : '100%', // 'auto' permettra au champ de s'ajuster avec le padding
                                              backgroundColor: 'rgba(247, 118, 31, 0.1)'
                                            }}
                                        />
                                        {errors.nom && <p className='text-red-500 text-xs'>{errors.nom}</p>}
                                        <input
                                            name="email"
                                            type="email"
                                            placeholder="Votre courriel"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="bg-transparent border-b-2 border-orange-500 py-3 outline-none placeholder:text-white transition-all duration-300"
                                            style={{
                                              borderColor: '#F08927',
                                              borderWidth: isMobile ? '1px' : '2px',
                                              paddingLeft: isMobile ? '1rem' : '1rem',  // ou tout autre valeur selon votre design
                                              paddingRight: isMobile ? '1rem' : '0', // Ajustez en conséquence
                                              marginTop: '1rem',
                                              width: isMobile ? 'auto' : '100%', // 'auto' permettra au champ de s'ajuster avec le padding
                                              backgroundColor: 'rgba(247, 118, 31, 0.1)'
                                            }}
                                        />
                                        {errors.email && <p className='text-red-500 text-xs'>{errors.email}</p>}
                                        <textarea
                                            name="message"
                                            rows="5"
                                            placeholder="Votre message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            className="bg-transparent border-b-2 border-orange-500 py-3 outline-none placeholder:text-white transition-all duration-300"
                                            style={{
                                              borderColor: '#F08927',
                                              borderWidth: isMobile ? '1px' : '2px',
                                              paddingLeft: isMobile ? '1rem' : '1rem',  // ou tout autre valeur selon votre design
                                              paddingRight: isMobile ? '1rem' : '0', // Ajustez en conséquence
                                              marginTop: '1rem',
                                              width: isMobile ? 'auto' : '100%', // 'auto' permettra au champ de s'ajuster avec le padding
                                              backgroundColor: 'rgba(247, 118, 31, 0.1)'
                                            }}
                                        />
                                        {errors.message && <p className='text-red-500 text-xs'>{errors.message}</p>}
                                        <button
                                            type="submit"
                                            className="btnBase4 mx-auto btn-lg lg:btn-lg xs:btn-sm xxs:btn-sm xxxs:btn-xs xxxxs:btn-xs xxxxxs:btn-xxs xxxxxs:p-2"
                                            style={{ borderColor: '#F08927', marginTop: '5rem', textAlign: 'center' }}
                                        >
                                            Envoyez-moi votre message
                                        </button>
                                    </CardItem>
                                </CardBody>
                            </CardContainer>
                        </form>
                    </ResponsiveDiv>
                </div>
                <ResponsiveDiv className='flex-4 flex justify-end items-center droitAuteur'
                    variants={fadeIn("right", 0.3)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.7 }}
                    style={{ display: 'inline-block', color: '#F7761F', margin: '0 auto', textAlign: 'center', marginTop: 'clamp(4rem, 6.5vw, 15rem)' }}
                >
                    2024 Porfolio Philippe Bolduc. Tous droits réservés<span>&nbsp;©</span>
                </ResponsiveDiv>
            </div>
        </section>
    )
};

export default Contact;

