import React, { useEffect, useState } from 'react';
import { IoHomeOutline } from 'react-icons/io5';
import { FaRegUser } from 'react-icons/fa';
import { MdOutlineMail } from 'react-icons/md';
import { BiDetail } from 'react-icons/bi';
import { MdOutlineWorkOutline } from 'react-icons/md';
import { Link } from 'react-scroll';

const Navbar = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isMobileXs, setIsMobileXs] = useState(window.innerWidth < 476);

  // Calcule 10vh en pixels en fonction de la hauteur actuelle de la fenêtre
  const calculateOffset = () => {
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    return vh * 0.15; // x% de la hauteur de la fenêtre (valeur de base : 0.5)
  };

  useEffect(() => {
    // Vérifiez si window est défini
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsMobileXs(window.innerWidth < 476);
      };

      // Ajoutez l'écouteur d'événement
      window.addEventListener('resize', handleResize);

      // Déclenchez manuellement la fonction handleResize au montage pour définir l'état initial
      handleResize();

      // Supprimez l'écouteur d'événement lors du démontage
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <nav className="fixed items-center justify-start left-4 top-56 2xl:top-48 h-full overflow-hidden z-50">
      <div className="container mx-auto scale-50 sm:scale-75 xl:scale-100">
        <div className="max-h-[500px] h-full bg-black/20 w-[96px] backdrop-blur-2xl flex flex-col items-center justify-between rounded-xl py-8"
          style={{
            border: '2px solid #F5981D',
            boxShadow: 'rgba(245, 152, 29, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset'
          }}
        >

          {/* Bouton hamburger */}
          <div className="2xl+1rem:hidden">
            <button
              className="p-1"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <div className="w-6 h-0.5 bg-white mb-1"></div>
              <div className="w-6 h-0.5 bg-white mb-1"></div>
              <div className="w-6 h-0.5 bg-white"></div>
            </button>
          </div>

          {/* Menu qui s'affiche ou se cache selon l'état isMenuOpen */}
          <div className={`flex-col ${isMenuOpen || window.innerWidth >= 1441 ? 'flex' : 'hidden'}`}>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              className='flex items-center justify-center py-5'
            >
              <IoHomeOutline className='cursor-pointer text-xl' /> {/* Icon Home */}
            </Link>
            <Link
              activeClass="active"
              to="services"
              spy={true}
              smooth={true}
              className='flex items-center justify-center py-5'
            >
              <MdOutlineWorkOutline className='cursor-pointer text-xl' /> {/* Icon Home */}
            </Link>
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={calculateOffset()} // ici nous utilisons la fonction pour calculer l'offset
              duration={500}
              className='flex items-center justify-center py-5'
            >
              <FaRegUser className='cursor-pointer text-xl' /> {/* Icon About */}
            </Link>
            <Link
              activeClass="active"
              to="project"
              spy={true}
              smooth={true}
              className='flex items-center justify-center py-5'
            >
              <BiDetail className='cursor-pointer text-xl' /> {/* Icon Home */}
            </Link>
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              className='flex items-center justify-center py-5'
            >
              <MdOutlineMail className='cursor-pointer text-xl sm:text-lg' /> {/* Icon Home */}
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;


