import { collection, addDoc } from 'firebase/firestore';
import { firestore } from './firebase'; // Assurez-vous que le chemin d'import est correct

export const sendMessageToFirestore = async (nom, email, message) => {
  try {
    await addDoc(collection(firestore, 'message-reacr-portfolio'), {
      nom,
      email,
      message,
      dateSoumission: new Date()
    });
    console.log('Message envoyé avec succès');
  } catch (error) {
    console.error("Erreur lors de l'envoi du message:", error);
  }
};
