// App.js
import './App.css';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Main from './components/Main';
import Services from './components/Services';
import About from './components/About';
import Project from './components/Project';
import Contact from './components/Contact';

function App() {
  return (
    <div className="overflow-hidden bg-bgcolor">
      <Header />
      <Navbar />
      <Main />
      <Services />
      <About />
      <Project />
      <Contact />
    </div>
  );
}

export default App;

